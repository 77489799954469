<template>
  <div></div>
</template>

<script>
import userAPI from "@/api/accounts/user.js";

export default {
  mounted() {
    userAPI
      .certify(
        this.$router.currentRoute.query.id,
        this.$router.currentRoute.query.hash,
        this.$router.currentRoute.query.expires,
        this.$router.currentRoute.query.signature
      )
      .then(res => {
        if (res.status == 200) {
          this.$router.push("/accounts/signup/result?email=" + res.data.email);
        } else {
          alert(res.data.errors[0].userMsg);
          this.$router.push("/introduction/main");
        }
      });
  }
};
</script>
